import {reactive,toRefs,onBeforeMount,onMounted,defineComponent,getCurrentInstance,provide} from 'vue';
import ImportGuidUtil ,{IImportGuidDataObj} from "./importGuidUtil";
export default defineComponent ({
    name: "importGuid",
    props:{
        params: {//查询条件
            type: Object,
            default:()=>{return{
                title:'导入数据'
            }}
        },
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let params=props.params;
        let dataObj:IImportGuidDataObj=reactive<IImportGuidDataObj>({
            utilInst:{} as any,
            importDataDialogVisible:false,
            uploadParams:{
                showShadow:false
            },
            refMap:new Map()
        })
        onBeforeMount(()=>{
            props.params.title=proxy.$t('importGuid.title')
            Object.assign(dataObj.uploadParams,props.params.uploadParams);
            dataObj.utilInst=new ImportGuidUtil(proxy,dataObj);
        })
        onMounted(()=>{})
        const downLoadTemplateFile=(options:any)=>{
            if(params.ownerInst && params.ownerInst.downLoadTemplateFile){
                params.ownerInst.downLoadTemplateFile(options);
            }else{
                utils.Tools.configBox({
                    message:proxy.$t('importGuid.downloadPre')+ '<span style="padding: 0px 5px;color: #00b4aa;font-weight: bold;font-size: 18px;">「'+options.downLoadFileName+'」</span>?',
                    type:'info',
                    sureFn:()=> {
                        utils.Api.downLoadResourceFile(params.downloadParams.action, options.sourceName, options.downLoadFileName);
                    }
                });
            }
        }
        //上传完成之后(可考虑干掉)
        const afterResult=(res:any)=>{
            if(params.ownerInst && params.ownerInst.afterResult){
                params.ownerInst.afterResult(res);
            }else{
                if((res.data && res.data.result) || res.result){
                    if(params.ownerInst && params.ownerInst.pageListRef)params.ownerInst.pageListRef.queryHandler();
                    dataObj.importDataDialogVisible=false;
                }
            }
        }

        return{
            ...toRefs(dataObj),downLoadTemplateFile,afterResult
        }
    }
});